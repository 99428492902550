import React from 'react';
import { FormattedMessage } from 'react-intl';
import Layout from '../../../components/Layout';
import SEO from '../../../components/SEO/SEO';

import './thankyou.scss';

const ThankYou = () => {
  return (
    <Layout pageName="thank-you-page" className="thank-you-page">
      <div id="thank-you-body" className="page-content-wrapper">
        <h1 id="thank-you-header">
          <FormattedMessage id="pages.thank-you.header" className="thank-you-header" />
        </h1>
        <p id="thank-you-text">
          <FormattedMessage id="pages.thank-you.text" />
        </p>
      </div>
    </Layout>
  );
};

export default ThankYou;

/* eslint-disable react/prop-types */
export const Head = ({ pageContext }) => (
  <SEO titleKey="pages.thank-you.page-title" intl={pageContext.intl}>
    <meta name="robots" content="noindex" />
  </SEO>
);
/* eslint-enable react/prop-types */
